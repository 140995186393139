module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":200,"linkImagesToOriginal":false,"withWebp":true,"wrapperStyle":"float: left; border-radius: 3%; width: 30%; height: 30%; margin: 5px 20px 0 5px;","showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"False Mirror","short_name":"False Mirror","description":"The official website of False Mirror, Dark Ambient by Tobias Hornberger.","start_url":"/","background_color":"#404140","theme_color":"#FF914D","display":"minimal-ui","icons":[{"src":"/images/icon-any-48.png","sizes":"48x48","type":"image/png","purpose":"any"},{"src":"/images/icon-any-96.png","sizes":"96x96","type":"image/png","purpose":"any"},{"src":"/images/icon-any-192.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"/images/icon-any-260.png","sizes":"260x260","type":"image/png","purpose":"any"},{"src":"/images/icon-any-512.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"/images/icon-maskable-48.png","sizes":"48x48","type":"image/png","purpose":"maskable"},{"src":"/images/icon-maskable-96.png","sizes":"96x96","type":"image/png","purpose":"maskable"},{"src":"/images/icon-maskable-192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"/images/icon-maskable-260.png","sizes":"260x260","type":"image/png","purpose":"maskable"},{"src":"/images/icon-maskable-512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"legacy":false,"crossOrigin":"use-credentials","theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
